<template>
  <div class="qualityManual-container">
    <div style="height: 100%">
      <div style="height: 100%" class="df">
        <div
          style="flex: 0 0 600px; height: 100%; width: 0; overflow-y: scroll"
        >
          <div style="padding: 10px; width: 100%" class="df jcb">
            <el-button
              type="primary"
              size="medium"
              class="mr10"
              style="height: 36px; border: none !important; margin-bottom: 20px"
              @click="$router.go(-1)"
              >返回</el-button
            >
            <div v-if="isEdit">
              <el-button
                type="success"
                size="medium"
                class="mr10"
                style="
                  height: 36px;
                  border: none !important;
                  margin-bottom: 20px;
                "
                @click="addStr"
                >新增字符串</el-button
              >
              <el-button
                type="primary"
                size="medium"
                class="mr10"
                style="
                  height: 36px;
                  border: none !important;
                  margin-bottom: 20px;
                "
                @click="submit"
                >完成</el-button
              >
            </div>
            <div v-else>
              <el-button
                type="primary"
                size="medium"
                class="mr10"
                style="
                  height: 36px;
                  border: none !important;
                  margin-bottom: 20px;
                "
                @click="isEdit = true"
                >修改</el-button
              >
            </div>
          </div>
          <div style="width: 100%; padding: 0 10px">
            <el-form
              ref="form"
              :model="replaceContentData"
              label-width="140px"
              style="width: 100%"
            >
              <el-card
                class="box-card"
                v-for="(item, index) in replaceContent"
                :key="index"
              >
                <div
                  v-if="isEdit"
                  slot="header"
                  class="df jcb"
                  style="padding: 0 !important; line-height: 20px"
                >
                  <span></span>
                  <el-button
                    style="color: #f56c6c; padding: 0"
                    type="text"
                    @click="delStr(index)"
                    >移除</el-button
                  >
                </div>
                <el-form-item
                  label="替换字符串名称"
                  :rules="{
                    required: true,
                    message: '替换字符串名称不能为空',
                    trigger: 'blur',
                  }"
                  :prop="'data.' + index + '.name'"
                >
                  <div style="width: 70%">
                    <el-input
                      v-model="item.name"
                      :disabled="!isEdit"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item
                  label="替换字符串"
                  :rules="{
                    required: true,
                    message: '替换字符串不能为空',
                    trigger: 'blur',
                  }"
                  :prop="'data.' + index + '.value'"
                >
                  <div style="width: 70%">
                    <el-input
                      v-model="item.value"
                      :disabled="!isEdit"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item
                  label="类型"
                  :rules="{
                    required: true,
                    message: '类型不能为空',
                    trigger: 'blur',
                  }"
                  :prop="'data.' + index + '.type'"
                >
                  <div style="width: 70%">
                    <el-select
                      v-model="item.type"
                      placeholder="请选择"
                      style="width: 100%"
                      :disabled="!isEdit"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </el-card>
            </el-form>
            <div
              class="df aic jcc"
              style="width: 100%; height: 800px"
              v-if="!replaceContent || !replaceContent.length"
            >
              <el-empty description="暂无替换字符串"></el-empty>
            </div>
          </div>
        </div>
        <div
          style="flex: 1; height: 100%; border-radius: 10px; overflow: hidden"
          v-loading="!pdf"
          v-if="showFlag.showPdf"
        >
          <div style="height: 100%; overflow-y: scroll">
            <vue-office-pdf
              :src="pdf"
              @rendered="renderedHandler"
              @error="errorHandler"
            />
          </div>
        </div>
        <div
          style="flex: 1; height: 100%; border-radius: 10px; overflow: hidden"
          v-loading="!docx"
          v-else-if="showFlag.showWord"
        >
          <vue-office-docx
            v-if="showAble"
            :src="docx"
            @rendered="renderedHandler"
            @error="errorHandler"
          />
          <div class="df aic jcc" style="width: 100%; height: 100%" v-else>
            <el-empty description="预览失败"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//引入VueOfficeDocx组件
import VueOfficeDocx from "@vue-office/docx";
import VueOfficePdf from "@vue-office/pdf";
//引入相关样式
import "@vue-office/docx/lib/index.css";
import api from "@/util/extra-api";

export default {
  components: {
    VueOfficeDocx,
    VueOfficePdf,
  },
  data() {
    return {
      docx: "", //设置文档网络地址，可以是相对地址,
      pdf: "",
      form: {},
      replaceContent: null,
      id: null,
      companyId: null,
      isEdit: false,
      name: "",
      url: null,
      showAble: true,
      options: [
        {
          value: "text",
          label: "字符串",
        },
        {
          value: "digit",
          label: "金额",
        },
      ],
    };
  },
  computed: {
    replaceContentData() {
      return {
        data: this.replaceContent,
      };
    },
    showFlag() {
      return {
        showPdf: this.pdf !== "",
        showWord: this.docx !== "",
      };
    },
  },
  mounted() {
    this.docx = JSON.parse(this.$route.query.url).url;
    if (JSON.parse(this.$route.query.url).previewUrl) {
      this.pdf = JSON.parse(this.$route.query.url).previewUrl;
    }
    this.url = this.$route.query.url;
    this.replaceContent =
      this.$route.query.replaceContent !== null &&
      this.$route.query.replaceContent !== undefined
        ? JSON.parse(this.$route.query.replaceContent).map(v=>{
          if(!v.type){
            v.type='text'
          }
          return v
        })
        : [];
    console.log(this.$route.query.replaceContent, this.replaceContent, "测试");
    this.id = this.$route.query.id;
    this.companyId = this.$route.query.companyId;
    this.name = this.$route.query.name;
  },
  methods: {
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          api
            .post(`/v1/wx/contract/update?companyId=${this.companyId}`, {
              name: this.name,
              url: this.url,
              id: this.id,
              replaceContent: this.replaceContent.length
                ? JSON.stringify(this.replaceContent)
                : null,
              companyId: this.companyId,
            })
            .then((res) => {
              if (res.code == 200) {
                this.isEdit = false;
              }
            });
          // console.log(this.data);
        } else {
          return false;
        }
      });
    },
    addStr() {
      this.replaceContent = this.replaceContent.concat({
        name: "",
        value: "",
        type:'text'
      });
    },
    delStr(index) {
      this.replaceContent = this.replaceContent.filter(
        (item, i) => i !== index
      );
    },
    renderedHandler() {
      console.log("渲染完成");
    },
    errorHandler() {
      console.log("渲染失败");
      this.showAble = false;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.qualityManual-container {
  padding: 0 !important;
  width: 100%;
  height: calc(100vh - 60px) !important;
  overflow: hidden;
  // height: 100vh;
}

.qualityManual-container-office {
  width: 100%;
  //   height: calc(100% - 55px);
  height: 100%;
}
/deep/.vue-office-docx {
  height: 100%;
}
.jcb {
  justify-content: space-between;
}

/deep/ .el-card__header {
  padding: 0px !important;
}

.box-card {
  margin-bottom: 10px;
}
</style>